import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fragment } from "react";
import Verification from "./pages/Verification";
import Home from "./pages/Home";
import ResetPass from "./pages/ResetPass";

function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/verify/:token" element={<Verification />}></Route>
          <Route path="/resetPassword/:token" element={<ResetPass />}></Route>
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
