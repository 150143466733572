import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { RiEyeCloseLine } from "react-icons/ri";
import { HiEye } from "react-icons/hi";

const ResetPass = () => {
  const params = useParams();
  const [pathName, setPathName] = useState(null);
  const [successMSG, setSuccessMSG] = useState(null);
  const [errorMSG, setErrorMSG] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordErrorMSG, setPasswordErrorMSG] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordNotMatchMSG, setPasswordNotMatchMSG] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const UpperCase = new RegExp("^(?=.*[A-Z])");
  const LowerCase = new RegExp("^(?=.*[a-z])");
  const Symbol = new RegExp("^(?=.*[@#\$%^&+=!*])");
  const length = new RegExp("^().{8,}");

  useEffect(() => {
    setPathName(params.token);
  }, [params]);

  useEffect(() => {}, [loader]);
  const onVerify = async () => {
    setLoader(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/reset_password`,
        {
          token: pathName,
          password: password,
        }
      );
      setSuccessMSG(data.message);
      setLoader(false);
    } catch (error) {
      console.log(error);
      const a = error.response.data
        ? error.response.data.error
        : "Something went wrong";
      setErrorMSG(a);
      setLoader(false);
    }
  };
  return (
    <>
      <Flex
        minHeight="100vh"
        width="full"
        align="center"
        justifyContent="center"
      >
        <Box
          borderWidth={1}
          px={4}
          width="full"
          maxWidth="500px"
          borderRadius={4}
          textAlign="center"
          boxShadow="lg"
        >
          <Box p={4}>
            <Box textAlign="center">
              <Text>
                <br></br>
              </Text>
              <Heading>Reset Password</Heading>
              <Text>
                <br></br>
              </Text>
            </Box>
            <Box my={8} textAlign="left">
              <FormControl isRequired>
                <FormLabel>New Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Enter your password"
                    onChange={(e) => {
                      setPasswordErrorMSG(null);
                      setPassword(e.target.value);
                      setErrorMSG(null);
                      setSuccessMSG(null);
                    }}
                    onBlur={(e) => {
                      if (!UpperCase.test(password)) {
                        setPasswordErrorMSG(
                          "Password must contains Upper case."
                        );
                      } else if (!LowerCase.test(password)) {
                        setPasswordErrorMSG(
                          "Password must contains Lower case."
                        );
                      } else if (!Symbol.test(password)) {
                        setPasswordErrorMSG("Password must contains @#$%^&+=.");
                      } else if (!length.test(password)) {
                        setPasswordErrorMSG(
                          "Password length should be greater than 8."
                        );
                      } else {
                        setPasswordErrorMSG(null);
                      }
                    }}
                  />
                  <InputRightElement width="4.5rem">
                    {show ? (
                      <Icon
                        as={HiEye}
                        aria-label="Hide"
                        onClick={handleClick}
                      />
                    ) : (
                      <Icon
                        as={RiEyeCloseLine}
                        aria-label="Show"
                        onClick={handleClick}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {passwordErrorMSG ? (
                <p style={{ color: "red" }}>{passwordErrorMSG}</p>
              ) : null}
              {passwordErrorMSG ? (
                <FormControl mt={4} isRequired>
                  <FormLabel>Confirm New Password :</FormLabel>
                  <Input
                    disabled={true}
                    type={show ? "text" : "password"}
                    placeholder="Enter your Confirm password"
                    onChange={(e) => {
                      setErrorMSG(null);
                      setSuccessMSG(null);

                      if (password !== e.target.value) {
                        setPasswordMatch(false);
                      } else {
                        setPasswordMatch(true);
                      }
                    }}
                  />
                </FormControl>
              ) : (
                <FormControl mt={4} isRequired>
                  <FormLabel>Confirm New Password :</FormLabel>

                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Enter your Confirm password"
                    onChange={(e) => {
                      setErrorMSG(null);
                      setSuccessMSG(null);

                      if (password !== e.target.value) {
                        setPasswordMatch(false);
                        setPasswordNotMatchMSG("Password not match");
                      } else {
                        setPasswordMatch(true);
                      }
                    }}
                  />
                </FormControl>
              )}

              {passwordMatch ? null : passwordErrorMSG ? null : (
                <p style={{ color: "red" }}>{passwordNotMatchMSG}</p>
              )}
              {loader ? (
                <Box align="center" my={4}>
                  <Spinner
                    thickness="6px"
                    speed="0.9s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Box>
              ) : passwordMatch ? (
                <Button
                  bgGradient="linear(to-b, #B6E4FA, #47B3E7)"
                  color="gery.500"
                  width="full"
                  mt={4}
                  type="reset"
                  onClick={onVerify}
                >
                  Reset Password
                </Button>
              ) : (
                <Button
                  bgGradient="linear(to-b, #B6E4FA, #47B3E7)"
                  color="gery.500"
                  width="full"
                  mt={4}
                  type="reset"
                  disabled={true}
                >
                  Reset Password
                </Button>
              )}
            </Box>
            <p style={{ color: "green" }}>{successMSG}</p>
            <p style={{ color: "red" }}>{errorMSG}</p>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default ResetPass;
