import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Button,
} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
const Verification = () => {
    const params = useParams()
    const [pathName, setPathName] = useState(null)
    const [successMSG, setSuccessMSG] = useState(null)
    const [errorMSG, setErrorMSG] = useState(null)
    useEffect(() => {
        setPathName(params.token)
    }, [params])

    const onVerify = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/verify_email`, {
                emailURL: pathName
            })
            setSuccessMSG(data.message)
        }
        catch (error) {
            console.log(error)
            setErrorMSG(error.message)
        }

    }
    return (
        <>
            {/* {pathName ?
                <button type='reset' onClick={() => {
                    onVerify()
                    setErrorMSG(null)
                    setSuccessMSG(null)
                }}> Verify Email</button> : null}
            <p>{successMSG}</p>
            <p>{errorMSG}</p> */}

            <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
                <Box
                    borderWidth={1}
                    px={4}
                    width="full"
                    maxWidth="500px"
                    borderRadius={4}
                    textAlign="center"
                    boxShadow="lg"
                >
                    <Box textAlign="center">
                        <Text>
                            <br></br>
                        </Text>
                        <Heading>Email Verification</Heading>
                    </Box>
                    <Box my={8} textAlign="left">
                        {pathName ?
                            <Button
                                bgGradient='linear(to-b, #B6E4FA, #47B3E7)'
                                color="gery.500"
                                width="full"
                                mt={4}
                                type='reset'
                                onClick={() => {
                                    onVerify()
                                    setErrorMSG(null)
                                    setSuccessMSG(null)
                                }}> Verify Email
                            </Button>
                            : null}
                    </Box>
                    <p style={{ color: "green" }}>{successMSG}</p>
                    <p style={{ color: "red" }}>{errorMSG}</p>
                </Box>
            </Flex>
        </>
    )
}

export default Verification